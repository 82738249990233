<template>
  <v-app-bar
    id="app-bar"
    :absolute="true"
    app
    color="transparent"
    flat
    height="60"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      small
      fab
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ count > 9 ? '9+' : count }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title
              @click="openNotification(n)"
              v-text="n.message"
            />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item>
            <v-list-item-title
              @click="profile()"
              v-text="buttons.profile"
            />
          </app-bar-item>
          <app-bar-item style="padding: 0px">
            <div
              @click="logout()"
            >
              <v-list-item-title
                style="margin: 20px 20px"
                v-text="buttons.logout"
              />
            </div>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations, mapActions } from 'vuex'
import notificationService from '@/shared/notifications'

export default {
  name: 'DashboardCoreAppBar',

  components: {
    AppBarItem: {
      render (h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(VListItem, {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text secondary elevation-12': hover
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs
                }
              }, this.$slots.default)
            }
          }
        })
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    periodicCheck: null,
    loading: false,
    count: 0,
    notifications: [],
    buttons: {
      profile: ' Your Profile ',
      logout: ' Logout from System '
    }
  }),

  computed: {
    ...mapState(['drawer']),
    isLoggedIn: function () {
      return this.$store.getters['login/isAuthenticated']
    }
  },
  mounted () {
    // regular
    this.createTimer()
    this.loading = true
    this.fetchNotifications()
    this.loading = false
  },
  destroyed () {
    clearInterval(this.periodicCheck)
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    }),
    ...mapActions(['Logout']),
    async openNotification (notification) {
      this.loading = true
      clearInterval(this.periodicCheck)
      await this.$router.push(notification.clickLink)
      await this.markAsRead(notification.id)
      await this.fetchNotifications()
      this.createTimer()
      this.loading = false
    },
    async logout () {
      await this.Logout()
      await this.$router.push('/login')
    },
    async profile () {
      await this.$router.push('/profile')
    },
    async fetchNotifications () {
      const response = await notificationService.findAllUnread({ page: 0, size: 10 }, {})
      if (response.status === 200) {
        this.notifications = response.data.content
        this.count = response.data.totalElements
      }
    },
    async markAsRead (id) {
      await notificationService.markRead(id)
    },
    createTimer () {
      this.periodicCheck = setInterval(() => {
        this.loading = true
        this.fetchNotifications()
        this.loading = false
      }, 3000)
    }
  }
}
</script>
